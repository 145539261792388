import axios, { AxiosResponse } from "axios"

class IpLocationService {
  private api: any

  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_IP_GEOLOCATION_URL}`,
    })
  }

  public getIpData = (): Promise<AxiosResponse<any>> => {
    return this.api.get(`?apiKey=${process.env.NEXT_PUBLIC_IP_GEOLOCATION_KEY}`)
  }
}

const ipLocationService = new IpLocationService()

export default ipLocationService
