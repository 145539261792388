// UTMContext.tsx
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react"

interface UTMParams {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
}

const defaultUTMParams: UTMParams = {
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
  utm_content: "",
}

const UTMContext = createContext<UTMParams>(defaultUTMParams)

export const useUTM = (): UTMParams => useContext(UTMContext)

interface UTMProviderProps {
  children: ReactNode
}

export const UTMProvider: React.FC<UTMProviderProps> = ({ children }) => {
  const [utmParams, setUtmParams] = useState<UTMParams>({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_content: "",
  })

  useEffect(() => {
    const handleStorageChange = () => {
      setUtmParams({
        utm_source: localStorage.getItem("utm_source") || "",
        utm_medium: localStorage.getItem("utm_medium") || "",
        utm_campaign: localStorage.getItem("utm_campaign") || "",
        utm_content: localStorage.getItem("utm_content") || "",
      })
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  return <UTMContext.Provider value={utmParams}>{children}</UTMContext.Provider>
}
