import React, { createContext, useEffect, useState } from "react"
import { toByteArray } from "base64-js"

const IPTrackingContext = createContext<any | null>(null)

const IPTrackingWrapper = (props: any) => {
  const [IPTrackingData, setIPTrackingData] = useState<any | null>(null)

  const getIPTrackingData = () => {
    const storeData = localStorage.getItem("userDta")
    if (storeData && typeof storeData === "string") {
      const decodedValue = new TextDecoder().decode(toByteArray(storeData))
      setIPTrackingData(decodedValue)
    }
  }

  useEffect(() => {
    getIPTrackingData()
  }, [IPTrackingData])

  return (
    <IPTrackingContext.Provider value={{ IPTrackingData, setIPTrackingData }}>
      {props.children}
    </IPTrackingContext.Provider>
  )
}

export { IPTrackingContext, IPTrackingWrapper }
