import Script from "next/script"

const ClarityScript = () => {
  const NEXT_PUBLIC_CLARITY_KEY = process.env.NEXT_PUBLIC_CLARITY_KEY

  if (NEXT_PUBLIC_CLARITY_KEY) {
    return (
      <Script
        id="clarity-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "k269qfdxo5");
          `,
        }}
      />
    )
  } else {
    return null
  }
}

export default ClarityScript
