import React, { useEffect, useState } from "react"
import { Button, Card, Space } from "antd"
import Link from "next/link"

const CookieBanner = () => {
  const [visible, setVisible] = useState<boolean | null>(null)

  const handleAccept = () => {
    localStorage.setItem("acceptCookies", "ACCEPT")
    console.log("Cookies accepted")
    setVisible(false)
  }
  const handleDecline = () => {
    localStorage.setItem("acceptCookies", "DECLINE")
    console.log("Cookies declined")
    setVisible(false)
  }
  const checkCookies = () => {
    const item = localStorage.getItem("acceptCookies")
    if (item) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }
  useEffect(() => {
    checkCookies()
  }, [])
  return (
    <>
      {false && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 9999,
          }}
        >
          <Card
            bordered={true}
            style={{
              margin: "2%",
              borderRadius: "24px",
              border: "1px solid #c8c8c8",
              width: "90%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h4>Gestiona tus cookies</h4>
                <p>
                  Fiva usa cookies para analizar el uso del sitio web.{" "}
                  <Link href="/cookies">Política de cookies</Link>
                </p>
              </div>
              <Space>
              <Button
                  size="large"
                  style={{
                    border: "1px solid #401c72",
                    color: "var(--fiva-color)",
                    borderRadius: "8px",
                    fontWeight: "bold",
                  }}
                  onClick={handleDecline}
                >
                  Rechazar
                </Button>
                <Button
                  size="large"
                  style={{
                    backgroundColor: "aqua",
                    color: "var(--fiva-color)",
                    borderRadius: "8px",
                    border: "1px solid",
                    fontWeight: "bold",
                  }}
                  onClick={handleAccept}
                >
                  Aceptar
                </Button>
            
              </Space>
            </div>
          </Card>
        </div>
      )}
    </>
  )
}

export default CookieBanner
