import React, { createContext, useState } from "react"
import { OcrDataDto } from "../interfaces/service.interface"
import { OcrContextType, OcrWrapperProps } from "../interfaces/context.types"

const OcrContext = createContext<OcrContextType | null>(null)

const OcrWrapper = (props: OcrWrapperProps) => {
  const [ocrData, setOcrData] = useState<OcrDataDto | null>(null)

  return (
    <OcrContext.Provider value={{ ocrData, setOcrData }}>
      {props.children}
    </OcrContext.Provider>
  )
}

export { OcrContext, OcrWrapper }
