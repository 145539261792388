import Script from "next/script"

const HubSpotScript = () => {
  return (
    <>
      {/* <Script
        type="text/javascript"
        id="hs-script-loader"
        strategy="afterInteractive"
        src="//js-eu1.hs-scripts.com/143788417.js"
        async
        defer
      /> */}
      {/* Performance */}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        strategy="afterInteractive"
        src="//js-eu1.hs-scripts.com/24953227.js"
        async
        defer
      />
    </>
  )
}

export default HubSpotScript
