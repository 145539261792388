import React, { createContext, useState } from "react"

const CollapseContext = createContext<any | null>(null)

const CollapseWrapper = (props: any) => {
  const [collapse, setCollapse] = useState<boolean>(false)

  return (
    <CollapseContext.Provider value={{ collapse, setCollapse }}>
      {props.children}
    </CollapseContext.Provider>
  )
}

export { CollapseContext, CollapseWrapper }
