import React, { createContext, useState } from "react"

const BillContext = createContext<any | null>(null)

const BillWrapper = (props: any) => {
  const [billData, setBillData] = useState<any | null>(null)

  return (
    <BillContext.Provider value={{ billData, setBillData }}>
      {props.children}
    </BillContext.Provider>
  )
}

export { BillContext, BillWrapper }
