import Script from "next/script"

const CookieYesScript = () => {
  return (
    <Script
      id="cookieyes"
      src="https://cdn-cookieyes.com/client_data/3da04928eff20d9c7eb8291c/script.js"
      type="text/javascript"
    />
  )
}

export default CookieYesScript
