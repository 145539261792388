import { useState, useEffect } from "react"

interface UTMParams {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
}

export const useUTMParams = (): UTMParams => {
  const [utmParams, setUtmParams] = useState<UTMParams>({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_content: "",
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Solo ejecutar en el cliente
      const storedUtmParams = {
        utm_source: localStorage.getItem("utm_source") || "",
        utm_medium: localStorage.getItem("utm_medium") || "",
        utm_campaign: localStorage.getItem("utm_campaign") || "",
        utm_content: localStorage.getItem("utm_content") || "",
      }

      setUtmParams(storedUtmParams)
      console.log("utmParams", storedUtmParams)
    }
  }, [])
  return utmParams
}
export const useSaveUTMParams = (): void => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const utm_source = urlParams.get("utm_source")
    const utm_medium = urlParams.get("utm_medium")
    const utm_campaign = urlParams.get("utm_campaign")
    const utm_content = urlParams.get("utm_content")

    console.log({
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      utm_content: utm_content,
    })

    if (utm_source) localStorage.setItem("utm_source", utm_source)
    if (utm_medium) localStorage.setItem("utm_medium", utm_medium)
    if (utm_campaign) localStorage.setItem("utm_campaign", utm_campaign)
    if (utm_content) localStorage.setItem("utm_content", utm_content)
  }, [])
}
