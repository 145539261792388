import ipLocationService from "../services/ipLocation.service"
import { fromByteArray } from "base64-js"

const fetchIp = async () => {
  try {
    const { data } = await ipLocationService.getIpData()
    return data
  } catch (error) {
    console.error(error)
  }
}
const handleIp = (ipData: any) => {
  localStorage.setItem("userDta", ipData)
}
const encode = (value: string | any): string => {
  const encoder = new TextEncoder()
  const data = encoder.encode(value)
  const encoded = fromByteArray(data)
  return encoded
}

export const IPTracking = async () => {
  const storeData = localStorage.getItem("userDta")
  if (!storeData) {
    const ipData = await fetchIp()
    handleIp(encode(JSON.stringify(ipData)))
  }
}
