import React from "react"
import Head from "next/head"
import { HeaderProps } from "../../interfaces/props.interface"
import { useRouter } from "next/router"

const HeadComponent: React.FC<HeaderProps> = ({
  title,
  keywords,
  content,
  imageUrl,
}) => {
  const router = useRouter()
  const canonicalURL: any =
    typeof window !== "undefined"
      ? `${window.location.origin}${router.asPath}`
      : "undefined"

  const image = imageUrl
    ? imageUrl
    : "https://res.cloudinary.com/dzzkeb6xp/image/upload/v1686222516/Recurso_rayo_1_yeu3lg.png"

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={content} />
      <meta name="keywords" content={keywords} />
      <meta name="google" content="notranslate" />
      <link rel="canonical" href={canonicalURL} key="canonical" />

      {/* <!-- White --> */}
      {/* <link
        rel="icon"
        href="https://res.cloudinary.com/dzzkeb6xp/image/upload/v1686222516/Recurso_rayo_1_yeu3lg.png"
      /> */}

      {/* <!-- Purple --> */}
      <link rel="icon" href="/favicon.ico" sizes="any" />
      <link rel="apple-touch-icon" href="/favicon.ico" sizes="any" />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={canonicalURL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={content} />
      <meta property="og:image" content={image} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="fiva.es" />
      <meta property="twitter:url" content={canonicalURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={content} />
      <meta name="twitter:image" content={image} />

      {/* Precarga de la fuente */}
      <meta
        name="google-site-verification"
        content="f4fH2k3OqfSFIhjb8aZ5FSQk1E8nkUcVZtKVdMptMeM"
      />
      <meta name="author" content="Fiva" />
      <meta name="author" content="Pablo Andreu" />
      <link rel="author" href="https://nextjs.org" />
      <meta name="generator" content="Next.js" />
      <meta name="referrer" content="origin-when-cross-origin" />
      <meta name="application-name" content="Fiva" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="publisher" content="Pablo Andreu" />

      {/* Hydration Errors  */}
      <meta
        name="format-detection"
        content="telephone=no, address=no, email=no"
      />

      {/* <!-- Facebook Verification --> */}
      <meta
        name="facebook-domain-verification"
        content="41xti46rrcqbmyc2cwxmzfshsbw3gr"
      />
    </Head>
  )
}

export default HeadComponent
