export const initializeUtmTracking = (utm: string | any): void => {
  if (utm) {
    setItemWithExpiry("UTM", utm, 2)
    console.log("UTM INITIALIZED")
  } else {
    console.log("NO UTM")
  }
}
export const findUtmTracking = (): string | null => {
  const utm: string | null = getItemWithExpiry("UTM")
  return utm
}
const setItemWithExpiry = (
  key: string,
  value: string,
  expiryInDays: number
) => {
  /**
   * Set data with an expiration time
   */

  const item = {
    value: value,
    expiry: new Date().getTime() + expiryInDays * 24 * 60 * 60 * 1000, // Calculate expiry timestamp
  }
  localStorage.setItem(key, JSON.stringify(item))
}
const getItemWithExpiry = (key: string) => {
  /**
   * Get data and check if it has expired
   */

  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  if (new Date().getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}
