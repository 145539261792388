import React, { useState, createContext, useEffect } from "react"
import { ComparativeDTO } from "../interfaces/service.interface"
import {
  ComparativeContextType,
  ComparativeWrapperProps,
} from "../interfaces/context.types"

const ComparativeCtx = createContext<ComparativeContextType | null | any>(null)

const ComparativeWrapper = (props: ComparativeWrapperProps) => {
  const [comparativeList, setComparativeList] = useState<ComparativeDTO | any>(
    () => {
      // Get the initial state from local storage if it exists
      if (typeof window !== "undefined") {
        const saved = localStorage.getItem("comparativeList")
        return saved ? JSON.parse(saved) : null
      } else {
        return null
      }
    }
  )

  useEffect(() => {
    // Save to local storage whenever the state changes
    localStorage.setItem("comparativeList", JSON.stringify(comparativeList))
  }, [comparativeList])

  return (
    <ComparativeCtx.Provider value={{ comparativeList, setComparativeList }}>
      {props.children}
    </ComparativeCtx.Provider>
  )
}

export { ComparativeCtx, ComparativeWrapper }
