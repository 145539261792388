import Script from "next/script"

const GoogleScript = () => {
  const NEXT_PUBLIC_TRACKING_KEY = process.env
    .NEXT_PUBLIC_TRACKING_KEY as string
  const GOOGLE_ADS_TRACKING_KEY = "AW-11029815948"
  return (
    <>
      {/* Google Tag Manager Script */}
      <Script
        strategy="afterInteractive"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-49KJTY8BW4"
      />

      {/* Google Ads Script */}
      {/* <Script
        strategy="afterInteractive"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADS_TRACKING_KEY}`}
      /> */}

      {/* Google Analytics */}
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config',${NEXT_PUBLIC_TRACKING_KEY});`,
        }}
      />
    </>
  )
}
export default GoogleScript
